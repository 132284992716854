var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',{staticClass:"d-flex align-items-center justify-content-between table-header"},[_c('h2',{staticClass:"text-primary mb-1"},[_vm._v(" "+_vm._s(typeof _vm.$route.meta.pageTitle === 'function' ? _vm.$route.meta.pageTitle() : _vm.$route.meta.pageTitle)+" "+_vm._s(_vm.namamenu)+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-button',{attrs:{"variant":"dark"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(("/transaksi/submitcustomer/form/" + (_vm.$route.params.id)))}}},[_c('feather-icon',{attrs:{"icon":"ListIcon"}}),_c('span',{staticClass:"d-none d-md-inline"},[_vm._v("Back To Detail")])],1)],1)]),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"paket","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nama Paket","label-for":"paket","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"paket","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.paketoptionfield,"label":"text","placeholder":"Pilih"},model:{value:(_vm.modelData.paket),callback:function ($$v) {_vm.$set(_vm.modelData, "paket", $$v)},expression:"modelData.paket"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.modelData.paket !== null && _vm.modelData.paket !== '')?_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"harga","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Harga Paket (Rp)","label-for":"harga"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"number","raw":false,"readonly":"","options":_vm.options.number,"placeholder":"Masukkan harga paket"},model:{value:(_vm.modelData.harga),callback:function ($$v) {_vm.$set(_vm.modelData, "harga", $$v)},expression:"modelData.harga"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,4019793018)})],1):_vm._e(),(_vm.modelData.paket !== null && _vm.modelData.paket !== '')?_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"jumlah","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Jumlah (Pax)","label-for":"jumlah"}},[_c('b-form-input',{attrs:{"id":"jumlah","placeholder":"Masukkan jumlah","type":"tel","pattern":"[0-9]*","maxlength":"2000","minlength":"0"},on:{"keypress":_vm.isLetter},model:{value:(_vm.modelData.jumlah),callback:function ($$v) {_vm.$set(_vm.modelData, "jumlah", $$v)},expression:"modelData.jumlah"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,3554927409)})],1):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[(_vm.modelData.paket !== null && _vm.modelData.paket !== '')?_c('b-col',{attrs:{"md":"12"}},_vm._l((_vm.resultMenuPaket),function(orderMenu,orderIndex){return _c('div',{key:orderIndex},[_c('validation-provider',{key:orderIndex,attrs:{"name":'nama makanan ' + orderIndex,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":orderMenu.namamenu,"label-for":'namamakanan' + orderIndex,"state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":'namamakanan' + orderIndex,"options":_vm.getFilteredNamamakanan(orderMenu.namamenu),"label":"text","placeholder":"Pilih"},on:{"input":_vm.updateTotalHarga},model:{value:(orderMenu.nama_makanan),callback:function ($$v) {_vm.$set(orderMenu, "nama_makanan", $$v)},expression:"orderMenu.nama_makanan"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)}),0):_vm._e()],1)],1),(_vm.modelData.paket !== null && _vm.modelData.paket !== '')?_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"total harga","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Total Harga Paket (Rp)","label-for":"totalharga"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"number","raw":false,"readonly":"","options":_vm.options.number,"placeholder":"Masukkan total harga paket"},model:{value:(_vm.totalHargaSeluruhnya),callback:function ($$v) {_vm.totalHargaSeluruhnya=$$v},expression:"totalHargaSeluruhnya"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,4057066825)})],1):_vm._e()],1),_c('b-button',{staticClass:"d-flex align-items-center mt-50",attrs:{"variant":"success"},on:{"click":function($event){$event.preventDefault();return _vm.handleCreate($event)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_vm._v(" Save ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }