<template>
    <b-card>
        <div class="d-flex align-items-center justify-content-between table-header">
            <h2 class="text-primary mb-1">
                {{ typeof $route.meta.pageTitle === 'function' ? $route.meta.pageTitle() : $route.meta.pageTitle }}
                {{ namamenu }}
            </h2>
            <div class="d-flex align-items-center">
                <b-button variant="dark"
                    @click.prevent="$router.push(`/transaksi/submitcustomer/form/${$route.params.id}`)">
                    <feather-icon icon="ListIcon" />
                    <span class="d-none d-md-inline">Back To Detail</span>
                </b-button>
            </div>
        </div>

        <b-row>
            <b-col md="6">
                <b-row>
                    <b-col md="12">
                        <validation-provider #default="{ errors }" name="paket" rules="required">
                            <b-form-group label="Nama Paket" label-for="paket"
                                :state="errors.length > 0 ? false : null">
                                <v-select id="paket" v-model="modelData.paket"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="paketoptionfield"
                                    label="text" placeholder="Pilih" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0]
                                    }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="12" v-if="modelData.paket !== null && modelData.paket !== ''">
                        <validation-provider #default="{ errors }" name="harga" rules="required">
                            <b-form-group label="Harga Paket (Rp)" label-for="harga">
                                <cleave id="number" v-model="modelData.harga" class="form-control" :raw="false" readonly
                                    :options="options.number" placeholder="Masukkan harga paket" />
                            </b-form-group>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </validation-provider>
                    </b-col>

                    <b-col md="12" v-if="modelData.paket !== null && modelData.paket !== ''">
                        <validation-provider #default="{ errors }" name="jumlah" rules="required">
                            <b-form-group label="Jumlah (Pax)" label-for="jumlah">
                                <b-form-input id="jumlah" placeholder="Masukkan jumlah" type="tel" pattern="[0-9]*"
                                    maxlength="2000" minlength="0" @keypress="isLetter" v-model="modelData.jumlah" />
                            </b-form-group>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </validation-provider>
                    </b-col>
                </b-row>
            </b-col>

            <b-col md="6">
                <b-row>
                    <b-col md="12" v-if="modelData.paket !== null && modelData.paket !== ''">
                        <div v-for="(orderMenu, orderIndex) in resultMenuPaket" :key="orderIndex">
                            <validation-provider :key="orderIndex" #default="{ errors }"
                                :name="'nama makanan ' + orderIndex" rules="required">
                                <b-form-group :label="orderMenu.namamenu" :label-for="'namamakanan' + orderIndex"
                                    :state="errors.length > 0 ? false : null">
                                    <v-select :id="'namamakanan' + orderIndex" v-model="orderMenu.nama_makanan"
                                        :options="getFilteredNamamakanan(orderMenu.namamenu)" label="text"
                                        @input="updateTotalHarga" placeholder="Pilih" />
                                </b-form-group>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                        </div>
                    </b-col>
                </b-row>
            </b-col>

            <b-col md="12" v-if="modelData.paket !== null && modelData.paket !== ''">
                <validation-provider #default="{ errors }" name="total harga" rules="required">
                    <b-form-group label="Total Harga Paket (Rp)" label-for="totalharga">
                        <cleave id="number" v-model="totalHargaSeluruhnya" class="form-control" :raw="false" readonly
                            :options="options.number" placeholder="Masukkan total harga paket" />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        {{ errors[0] }}
                    </b-form-invalid-feedback>
                </validation-provider>
            </b-col>
        </b-row>

        <b-button class="d-flex align-items-center mt-50" variant="success" @click.prevent="handleCreate">
            <feather-icon class="mr-50" icon="SaveIcon" />
            Save
        </b-button>
    </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        vSelect,
        Cleave,
        BFormInvalidFeedback,
        ToastificationContent,
    },
    data() {
        return {
            modelData: {
                paket: '',
                harga: '',
                jumlah: '',
            },
            options: {
                number: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                }
            },
            kodeorder: 0,
            idPaket: 0,
            hargaPaket: 0,
            totalHargaSeluruhnya: 0,
            totalHargaSeluruhnyaAwal: 0,
            totalUpdate: 0,
            idMenu: 0,
            paketoptionfield: [],
            resultMenuPaket: [],
            makananoptionfield: [],
            loading: false,
            namamenu: ''
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.getDetailData()
        }
    },
    watch: {
        'modelData.paket': 'handlePaketChange',
        'modelData.jumlah': 'updateTotalHargaSeluruhnya',
        'modelData.harga': 'updateTotalHargaSeluruhnya',
    },
    methods: {
        isLetter(e) {
            let char = String.fromCharCode(e.keyCode);
            if (/.*[0-9]/.test(char)) return true;
            else e.preventDefault();
        },

        handlePaketChange() {
            this.getMenuOptional();
        },

        updateTotalHarga() {
            let totalHarga = 0;
            this.resultMenuPaket.forEach(orderMenu => {
                totalHarga += parseInt(orderMenu.menuoptional.find(option => option.namamakanan === orderMenu.nama_makanan.text)?.hargaupgrade) || 0
            });
            let totalseluruhnya = totalHarga + this.hargaPaket || 0;
            this.modelData.harga = totalseluruhnya;
            this.totalUpdate = totalHarga
        },

        updateTotalHargaSeluruhnya() {
            const jumlah = parseFloat(this.modelData.jumlah) || 0;
            this.totalHargaSeluruhnya = (this.totalUpdate + this.hargaPaket) * jumlah;
        },

        handleCreate() {
            if (!this.validateForm()) {
                return;
            }

            const orderDetailMenuArray = this.resultMenuPaket.map(orderMenu => ({
                punyaoptional: parseInt(orderMenu.punyaoptional),
                kodemenu: parseInt(orderMenu.kodemenu),
                kodemakanan: orderMenu.nama_makanan.text ? parseInt(orderMenu.nama_makanan.value) : parseInt(orderMenu.kode_makanan),
                hargaupgrade: parseInt(orderMenu.menuoptional.find(option => option.namamakanan === orderMenu.nama_makanan.text)?.hargaupgrade) || 0,
            }));

            const params = {
                kodeorder: parseInt(this.$route.params.id),
                totalharga: this.totalHargaSeluruhnyaAwal + ((this.totalUpdate + this.hargaPaket) * parseInt(this.modelData.jumlah)),
                orderdetail: [{
                    kodepaket: parseInt(this.modelData.paket.value),
                    jumlah: parseInt(this.modelData.jumlah),
                    harga: this.hargaPaket,
                    hargaupgrade: this.totalUpdate,
                    totalharga: ((this.totalUpdate + this.hargaPaket) * parseInt(this.modelData.jumlah)),
                    orderdetailmenu: orderDetailMenuArray,
                }],
            };

            console.log(JSON.stringify(params))

            this.$http.post('/api/transaksi/paket/add', params).then(res => {
                if (!res.data.status) {
                    this.$toast({
                        component: ToastificationContent, position: 'top-right',
                        props: {
                            title: 'Notification',
                            icon: 'SlashIcon',
                            variant: 'danger',
                            text: res.data.message,
                        },
                    });
                    return
                }

                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: '👋Berhasil menyimpan data',
                    },
                });

                setTimeout(() => {
                    this.$router.push(`/transaksi/submitcustomer/form/${this.$route.params.id}`);
                }, 1000);
            }).catch(e => {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'SlashIcon',
                        variant: 'danger',
                        text: 'Gagal menyimpan data' + e,
                    },
                });
            })
        },

        getDetailData() {
            this.$http.get(`/api/transaksi/detail/${this.$route.params.id}`).then(res => {
                const response = res.data.data[0]
                this.kodeorder = parseInt(response.kodeorder)
                this.totalHargaSeluruhnyaAwal = parseInt(response.totalharga)
            }).catch(e => {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'SlashIcon',
                        variant: 'danger',
                        text: 'Gagal mendapatkan data' + e,
                    },
                });
            })

            const params = {
                page: 1,
                perPage: 100000
            };

            this.$http.get(`/api/paket/list`, { params: params }).then(response => {
                response.data.data.forEach(reason => {
                    const paketOption = { text: reason.nama, value: reason.kodepaket };
                    if (!this.paketoptionfield.some(opt => opt.value === paketOption.value)) {
                        this.paketoptionfield.push(paketOption);
                    }
                });

            }).catch(e => {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'SlashIcon',
                        variant: 'danger',
                        text: 'Gagal mendapatkan data' + e,
                    },
                });
            })
        },

        getFilteredNamamakanan(namamenu) {
            const filteredNamamakananByPaket = this.resultMenuPaket.flatMap(item => (
                item.menuoptional
                    .filter(item => item.kelompok === namamenu)
                    .map(item => ({
                        text: item.namamakanan,
                        value: item.kodemakanan
                    }))
            ));
            return filteredNamamakananByPaket;
        },

        getMenuOptional() {
            this.$http.get(`/api/menu/listmenupaketlandingcustom/${this.modelData.paket.value}`).then(res => {
                const response = res.data.data;
                this.modelData.harga = parseInt(response.hargapaket)
                this.hargaPaket = parseInt(response.hargapaket)
                this.resultMenuPaket = response.menu
            }).catch(e => {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'SlashIcon',
                        variant: 'danger',
                        text: 'Gagal mendapatkan data' + e,
                    },
                });
            });
        },

        validateForm() {
            if (this.modelData.paket === '' || this.modelData.paket === null) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Silakan pilih paket',
                    },
                });
                return false;
            }

            if (this.modelData.jumlah === '' || this.modelData.jumlah === null) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Silakan masukkan jumlah paket',
                    },
                });
                return false;
            }

            return true
        }
    }
}
</script>